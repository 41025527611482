.container {
    padding: 20px;
}

.content {
    /* max-width: 600px; */
    margin: 0 auto;
}

.form {
    margin-bottom: 20px;
}

.searchBox {
    display: flex;
    align-items: center;
    gap: 10px;
}

.textArea {
    flex-grow: 1;
    padding: 10px 15px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
    resize: none;
}

.button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: white;
    color: #007bff;
    border: 1px solid #007bff;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;
    align-self: stretch;
}

.button:hover {
    background-color: #f8f9fa;
}

.button:disabled {
    background-color: #cccccc;
    cursor: default;
    box-shadow: none;
}

.historyList {
    list-style-type: none;
    padding: 0;
    margin-top: 20px;
}

.historyItem {
    background: #fff;
    margin: 10px 0;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.1);
    display: grid;
    gap: 5px;
}

.response, .historySection {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-top: 20px;
}

.historySection h3 {
    margin-top: 0;
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3); /* Light grey border */
    border-top: 4px solid #3498db; /* Blue border for the top side */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
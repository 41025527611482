/* MainLayout.css */
.main-layout {
  display: flex;
  flex-direction: row; /* Explicitly define the flex direction */
}

.main-content {
  flex-grow: 1;
  margin-left: 250px; /* Adjust based on the side menu width */
}

@media (max-width: 768px) {
  .main-layout {
    flex-direction: column;
  }

  .main-content {
    margin-left: 0;
  }
}

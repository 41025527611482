h2, h3, h4 {
  font-weight: 300; /* Lighter font weight for a thinner look */
  color: #333333; /* Darker color for contrast */
  margin-bottom: 15px;
}

/* Optionally, if you want to make the main title (h2) a bit more prominent */
h2 {
  font-size: 1.5em; /* Larger size for the main title */
  font-weight: 400; /* Slightly bolder than other headings */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
  text-align: center;
}

nav li {
  display: inline;
  margin: 0;
}

nav li a {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
}

nav li a:hover {
  background-color: #111;
}

.header-container {
  background-color: #1d2327;
  color: white;
  padding: 20px 10px;
  text-align: center;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.header-title {
  font-size: 2em;
  margin: 0;
  font-weight: bold;
}

.header-subtitle {
  font-size: 1.2em;
  margin-top: 5px;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(84, 84, 84, 0.6);
  
}

.modal img {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

.modal-content {
  background-color: white; /* Solid white background */
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  margin-top: 15px;
  margin-left: 0;
  margin-bottom: 30px;
}

.close-modal {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #2c9ffc;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.modal button {
  display: block;
  margin: auto;
  margin-top: 20px;
}

.remove-button {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 2px 5px;
  font-size: 10px;
  color: white;
  background-color: red;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

/* ensure dashboard have correct margin applied on large screens and small screens */
.container-flexible-margin {
  margin-left: 0;
  width: 90vw;
}

.no-items-message {
  padding: 20px 20px 20px 0;
  color: #555;
  font-style: italic;
}

textarea::placeholder {
  font-weight: 300; /* Thinner font weight */
  font-family: 'Helvetica Neue', Arial, sans-serif; /* Font family similar to headers */
  color: #888; /* Optional: You can adjust the color to make it lighter or darker */
}


@media (min-width: 768px) {
  .container-flexible-margin {
      margin-left: 250px;
      width: calc(90vw - 250px);
  }

  .modal-content {
    margin-left: 250px;
  }
}

.bible-viewer-container {
    font-family: 'Arial', sans-serif;
    margin: auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .dropdown {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  
  .books-section {
    margin-bottom: 20px;
  }
  .books-section input::placeholder {
    font: 0.80rem/3 sans-serif;
    }
  
  .filter-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .books-list {
    list-style-type: none;
    padding: 0;
  }
  
  .books-list li {
    padding: 8px 10px;
    margin-bottom: 5px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .books-list li:hover {
    background-color: #e9e9e9;
  }
  
  .chapter-content {
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 20px;
  }
  
  input[type="text"] {
    width: 100%; /* Responsive width */
    padding: 8px 12px; /* Comfortable padding */
    border: 1px solid #ccc; /* Subtle border */
    border-radius: 4px; /* Slightly rounded corners */
    font-size: 1em; /* Readable text size */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  
  input[type="text"]:hover {
    border-color: #888; /* Slightly darker border on hover */
  }
  
  input[type="text"]:focus {
    outline: none; /* Remove default focus outline */
    border-color: #555; /* Darker border for focus state */
    box-shadow: 0 0 5px rgba(0,0,0,0.2); /* Subtle shadow to highlight focus */
  }
  
  .large-button {
    padding: 10px 20px;
    font-size: 1.2em; /* Larger text */
    background-color: #007bff; /* Bright color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
    transition: background-color 0.3s;
  }
  
  .large-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
  
  .floating-buttons-container {
    position: fixed; /* Fixed position relative to the viewport */
    right: 20px; /* 20px from the right */
    bottom: 20px; /* 20px from the bottom */
    z-index: 1000; /* High z-index to keep it above other elements */
  }
  
  .floating-button {
    padding: 10px 20px;
    
    width: auto;
    font-size: 1em;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin: 5px; /* Add space between buttons */
  }
  
  
  .floating-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
  
  /* Optional: Add a shadow for better visibility */
  .floating-button {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  input[type="checkbox"] {
    width: 20px;  /* Increased width */
    height: 20px; /* Increased height */
    cursor: pointer; /* To indicate it's clickable */
  }
  
  /* Optional: Adjust the margin or padding around the checkbox for better alignment */
  input[type="checkbox"] {
    margin-right: 5px; /* Add some space between checkbox and label/text */
  }
  

  /* Recently Generated Images */
  /* Styles for the recently generated images section */
.recently-generated-images-section {
  margin-top: 20px;
  padding: 15px;
  background-color: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.recent-images-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the thumbnails */
  gap: 10px; /* Spacing between thumbnails */
}

.recent-image {
  text-align: center;
}

.thumbnail {
  width: 100px; /* Thumbnail width */
  height: auto;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  opacity: 0;
  animation: fadeInAnimation 1s forwards;
}

.image-verse {
  margin-top: 5px;
  font-size: 0.8em;
  color: #555;
}

/* history section */

.history-section {
  margin-top: 20px;
}

.history-entry {
  border: 1px solid #ccc; /* Light grey border */
  background-color: #f9f9f9; /* Light grey background */
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
}

.history-entry h4 {
  margin-top: 0;
}

.history-entry p {
  margin-bottom: 10px;
  line-height: 1.5;
}

.history-entry button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 5px 10px;
  margin-top: 10px;
  cursor: pointer;
}

.delete-history-button {
  background-color: #ff6347; /* Tomato color for delete button */
  color: white;
  border: none;
  padding: 5px 10px;
  margin-top: 10px;
  margin-left: 5px; /* To separate from expand/collapse button */
  cursor: pointer;
}

.ai-search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Dimmed background */
  display: flex;
  align-items: center;
  justify-content: center;
}

.ai-search-modal {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  width: 50%; /* Adjust as needed */
}

.selection-row {
  display: flex; /* Enables Flexbox */
  gap: 10px; /* Adjust this value as needed to control space between elements */
  margin-bottom: 20px; /* Optional: Adjust as needed */
}

.selected-chapter-info {
  font-size: 1.2em;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
}

.chapter-summary {
  margin-top: 10px;
  font-style: italic;
  color: #555;
}

@keyframes fadeInAnimation {
  to {
    opacity: 1;
  }
}

.image-viewer-container {
    margin: auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .text-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical; /* Allow vertical resizing */
  }
  
  .submit-button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #45a049;
  }
  
  img {
    max-width: 100%;
    height: auto;
    margin-top: 20px;
  }
  
  .loader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    margin: 20px auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  /* css for thumbnails and modals */

  .thumbnail {
    margin: 10px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    width: 110px;
  }
  
  .thumbnail img {
    width: 100px; /* Thumbnail width */
    height: 100px; /* Thumbnail height */
    object-fit: cover; /* This makes the image cover the area, cropping if necessary */
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
  }
  
  .thumbnail p {
    max-width: 100px;
    word-wrap: break-word;
    text-align: center;
    margin-top: 5px;
  }
  
  
/* Dashboard.css */

.dashboard {
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* Single column layout initially */
    gap: 20px;
    padding: 20px;
    margin-left: 250px; /* Adjust based on your side menu's width */
    transition: margin-left 0.3s ease;
  }
  
  .dashboard-item {
    background-color: #fff; /* Card background color */
    border: 1px solid #ddd; /* Border for cards */
    border-radius: 10px; /* Rounded corners for cards */
    padding: 20px; /* Padding inside each card */
    text-align: center; /* Center-align the text */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    text-align: center;
  }
  
  .dashboard-item h3 {
    margin-top: 0; /* Removes default top margin from h3 */
    color: #333; /* Title color */
  }
  
  .dashboard-item p {
    color: #666; /* Paragraph/text color */
  }
  
  .icon {
    font-size: 2em; /* Adjust size as needed */
    margin-bottom: 10px;
  }
  
  .dashboard-button {
    background-color: #007bff; /* Change to blue */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .dashboard-button:hover {
    background-color: #0056b3; /* A slightly darker shade for hover */
  }
  
  @media (max-width: 768px) {
    .dashboard {
      margin-left: 0;
    }
  }
  
  @media (min-width: 600px) {
    .dashboard {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 900px) {
    .dashboard {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (min-width: 1200px) {
    .dashboard {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  .dashboard .dashboard-item {
    color: inherit; /* Or specify a color */
    text-decoration: none; /* Remove underline */
  }
  
  /* If you want to specifically style the button differently */
  .dashboard .dashboard-item .dashboard-button {
    text-decoration: none; /* Remove underline from the button */
    /* Additional button styling */
  }
  
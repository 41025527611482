/* SideMenu.css */

.side-menu-container {
    position: relative;
  }
  
  .hamburger-menu {
    display: none; /* Hidden by default */
    position: fixed;
    top: 10px;
    left: 10px;
    font-size: 15px;
    background: #1d2327;
    border: none;
    color: white;
    cursor: pointer;
    z-index: 1000;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .side-menu {
    width: 250px;
    border-right: 1px solid #ddd;
    background-color: #1d2327;
    color: white;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    transform: translateX(0);
    transition: transform 0.3s ease;
  }
  
  .menu-subhead {
    color: #aaa;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 0.8em;
    font-weight: bold;
  }
  
  .menu-items {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .menu-items li {
    padding: 10px 20px;
    border-bottom: 1px solid #444;
    cursor: pointer;
    font-size: 0.95em;
    color: #ddd;
  }
  
  .menu-items li:hover {
    background-color: #222;
  }
  
  .brand-logo {
    margin-top: 0;
    padding-right: 10px;
  }
  
  .brand-logo img {
    max-width: 30px;
    max-height: 30px;
    height: auto;
    padding-right: 3px;
  }

  /* Authentication syles */
  .logout-section {
    position: absolute;
    bottom: 20px;
    width: 100%;
    padding: 0 20px; 
  }
  
  .logout-button {
    display: block;
    padding: 10px;
    background-color: #74231d;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px; 
    text-align: center;
  }
  
  .logout-button:hover {
    background-color: #d32f2f; 
  }
  
  
  @media (max-width: 768px) {
    .hamburger-menu {
      /* display: block;  */
       display: none; 
    }
  
    .side-menu {
      transform: translateX(-100%);
    }
  
    .side-menu.open {
      transform: translateX(0);
    }
  
    .dashboard-content {
      margin-left: 0;
    }
  
    .side-menu.open + .dashboard-content {
      margin-left: 250px;
    }

    .brand-logo {
      margin-top: 20px;
    }
  }
  
  .side-menu-container .menu-items a {
    color: inherit; 
    text-decoration: none; 
  }
  
  .side-menu-container .menu-items a:hover {
    text-decoration: underline;
  }